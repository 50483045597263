<template>
  <div
    class="stds-input-container"
    :class="noContainerClass ? 'none-style' : containerClassRenderer"
  >
    <div class="stds-input-wrapper" :class="wrapperClass">
      <input
        :id="inputId"
        ref="inputTextRef"
        v-model="value"
        :type="type"
        :name="fieldName"
        :placeholder="placeholder"
        class="stds-input stds-input-no-number-control"
        :class="inputClassRenderer"
        :disabled="disabled"
        :maxlength="allowInputMaxLength ? undefined : maxLength"
        :readonly="readonly"
        @keydown="handleMaxlengthForNumber($event)"
        @input="onInput"
        @paste.stop.prevent="onPaste"
        @click="emit('click')"
        @keyup.enter="onEnter"
        @blur="onBlur"
        @focus="$emit('focus')"
      />
      <button
        v-if="showClearButton"
        type="button"
        class="stds-input-clear-button"
        @click="onClearSearch"
      >
        <s-icon size="lg" icon="ic-v2-control-close-circle-fill" srOnlyText="Clear" />
      </button>
      <span
        v-if="maxLength && countable"
        class="inline-flex shrink-0 text-2xs leading-xs text-placeholder"
        :class="{ hidden: disabled, '!text-orange600': inputLength > Number(maxLength) }"
      >
        <em
          class="text-on-surface-elevation-2"
          :class="{
            'text-placeholder': inputLength === 0,
            'text-orange600': inputLength > Number(maxLength)
          }"
        >
          {{ inputLength ?? 0 }}
        </em>
        /{{ maxLength }}
      </span>
      <slot name="right"></slot>
    </div>
    <button
      v-if="searchable"
      class="stds-search-field-search-button stove-ui-icon before:ic-v2-navigation-search-line text-[2rem]"
      :class="disabled ? 'cursor-default' : 'cursor-pointer'"
      @click.self="$emit('search')"
    >
      <span class="sr-only">Search</span>
    </button>
  </div>
  <div v-if="rules" class="relative">
    <st-error-message
      :name="fieldName"
      :data="{ length: maxLength }"
      :showError="showFieldError"
      :class="{
        absolute: absoluteErrorMsg,
        'bottom-[-1.8rem] !mt-0': smallSpace && absoluteErrorMsg
      }"
    />
  </div>
</template>

<script lang="ts" setup>
import { SIcon } from '@stove-ui/vue';
import { computed, ref, watch } from 'vue';
import { useCurrencyInput } from 'vue-currency-input';

import StErrorMessage from '@/components/validation/st-error-message.vue';
import useValidation from '@/composables/useValidation';
import { CONTROL_KEYS, NAVIGATION_KEYS } from '@/constants/validation-component.const';
import { Position } from '@/enums/common.enum';
import { InputTextTypes } from '@/enums/validation.enum';
import type { InputTextProps } from '@/types/common/form.type';
import { formatNumberMultipleWithCommas, getCurrencyInputOptions } from '@/utils/currency.util';
import { delayTime } from '@/utils/time.util';

const props = withDefaults(defineProps<InputTextProps>(), {
  searchable: false,
  variant: 'outline',
  allowInputMaxLength: true,
  clearable: true,
  size: 'lg',
  debounce: 0,
  caretPosition: Position.LEFT,
  type: InputTextTypes.Text,
  countable: true,
  readonly: false,
  preventInput: false,
  absoluteErrorMsg: false,
  smallSpace: false
});

const emit = defineEmits<{
  input: [e: Event];
  'update:modelValue': [v: string];
  change: [v?: string];
  onDebounced: [v: string];
  search: [];
  click: [];
  clear: [];
  blur: [];
  focus: [];
  keydown: [e: KeyboardEvent];
}>();

const value = ref(props.modelValue);

const setFieldValue = ref<(value: string) => void>();
const inputTextRef = ref();
const showFieldError = ref(false);

const fieldName = computed<string>(() => props.name ?? '');
const inputLength = computed<number>(
  () => props.maskLength ?? value.value?.toString()?.length ?? 0
);

const rules = computed(() => props.rules);

if (fieldName.value) {
  const { setValue, showError } = useValidation<string>({
    fieldName: fieldName.value,
    rules,
    value
  });
  setFieldValue.value = setValue;
  showFieldError.value = showError.value;

  watch(
    () => showError.value,
    (v: boolean) => {
      showFieldError.value = v;
    }
  );
}

if (props.currencyOptions) {
  const { inputRef } = useCurrencyInput(getCurrencyInputOptions({ ...props.currencyOptions }));
  inputTextRef.value = inputRef;
}

const containerClassRenderer = computed(() => ({
  'bg-neutral-variant-3 border-border': props.variant === 'outline',
  'border-transparent bg-neutral-variant-2': props.variant === 'fill',
  'h-44 pl-16 pr-16 focus-within:pr-[1.5rem] focus-within:pl-[1.5rem]': props.size === 'lg',
  'h-32 pl-12 pr-12 focus-within:pr-[1.1rem] focus-within:pl-[1.1rem]': props.size === 'sm',
  'border-2 pr-[1.1rem] pl-[1.1rem] border-error': showFieldError.value,
  '!bg-disabled-variant-3': props.disabled && !props.readonly,
  [`${props.containerClass}`]: !!props.containerClass
}));

const inputClassRenderer = computed(() => ({
  'text-xs leading-xs': props.size === 'sm',
  'text-md leading-sm': props.size === 'lg',
  'text-right': props.caretPosition === Position.RIGHT,
  [`${props.inputClass}`]: !!props.inputClass
}));

const showClearButton = computed(
  () => !props.disabled && value.value && value.value.length > 0 && props.clearable
);

watch(
  () => props.modelValue,
  (v?: string) => {
    value.value = v ?? '';
  }
);

const handleModelUpdate = async (value: string, isClear?: boolean) => {
  if (setFieldValue.value) {
    setFieldValue.value(value);
  }

  emit('update:modelValue', value);
  if (isClear) {
    emit('clear');
  }
  if (props.debounce) {
    await delayTime(props.debounce);
    emit('onDebounced', value);
  }
};

const onClearSearch = () => {
  handleModelUpdate('', true);
  emit('clear');
};

const onBlur = () => {
  let newValue = value.value;
  if (typeof newValue === 'string') {
    newValue = newValue.trim();
  } else {
    newValue = (newValue || '').toString().trim();
  }
  if (props.type === InputTextTypes.Number && (props.min || props.min === 0)) {
    if (newValue === '' || Number(newValue) < props.min) {
      newValue = '';
    }
  }

  if (props.type === InputTextTypes.Number) {
    const numberText = newValue ? Number(newValue).toString() : '';
    value.value = numberText;
    handleModelUpdate(numberText);
  } else {
    value.value = newValue;
    handleModelUpdate(value.value ?? '');
  }

  emit('blur');
};

const updateValidationValueForInputNumber = (inputValue: string): void => {
  let changedValue = inputValue;

  if (props.max) {
    if (Number(inputValue) > props.max) {
      changedValue = '';
    }
  }

  if (props.min || props.min === 0) {
    if (Number(inputValue) < props.min) {
      changedValue = '';
    }
  }

  value.value = changedValue;
  handleModelUpdate(changedValue);
};

const onEnter = async (e: Event) => {
  const target = e.target as HTMLInputElement;
  await handleModelUpdate(target.value);
  emit('search');
};

const formatNumberText = (inputValue: string, target?: HTMLInputElement) => {
  if (props.isFormatNumber) {
    const numberValue = inputValue?.replace(/,/g, '');
    if (!isNaN(Number(numberValue))) {
      const formattedNumber = formatNumberMultipleWithCommas(Number(numberValue));
      value.value = formattedNumber;
      handleModelUpdate(formattedNumber);
      if (target) {
        target.value = formattedNumber;
      }
      return true;
    }
  }
};

const onInput = (e: Event) => {
  emit('input', e);
  const target = e.target as HTMLInputElement;
  const typedValue = (e as InputEvent).data ?? '';
  const { value: inputValue } = target;
  const selectionStart = target.selectionStart ?? 0;
  const currentValue = inputValue.slice(0, selectionStart - 1) + inputValue.slice(selectionStart);

  // bypass IME issue
  if ((e as InputEvent).data === undefined) {
    return;
  }

  if (props.pattern) {
    if (props.pattern.test(typedValue)) {
      if (formatNumberText(inputValue, target)) {
        return;
      }
      handleModelUpdate(inputValue);
      return;
    }
    if ((e as InputEvent).data === null) {
      return;
    }
    const newValue = currentValue.replace(new RegExp(`[^${props.pattern.source}]`, 'g'), '');
    value.value = currentValue;
    handleModelUpdate(currentValue);
    target.value = newValue;
    return;
  }
  if (props.maxLength && !props.allowInputMaxLength) {
    // This is only use for korean input.
    if (inputValue.length > parseInt(props.maxLength as string, 10)) {
      handleModelUpdate(inputValue.substring(0, inputValue.length - 1));
      return;
    }
  }

  if (props.type === InputTextTypes.Number) {
    if (['+', '-', '.'].includes(typedValue)) {
      const newValue = inputValue.replace(/[+-.]/g, '');
      target.value = newValue;
      value.value = newValue || '0';
      handleModelUpdate(newValue || '0');
      return;
    }
    const targetValue = Number(target.value);
    if (props.max && !isNaN(targetValue) && targetValue > props.max) {
      target.value = '';
      value.value = '';
      return;
    }

    if (isNaN(Number(typedValue))) {
      target.value = currentValue;
      return;
    }

    updateValidationValueForInputNumber(inputValue);
    return;
  }

  // isFormatNumber can't go with type Number
  if (formatNumberText(inputValue)) {
    return;
  }

  value.value = inputValue;
  handleModelUpdate(inputValue);
};

const handleMaxlengthForNumber = (e: KeyboardEvent) => {
  emit('keydown', e);

  if (props.type === InputTextTypes.Number) {
    const currentValue = Number(value.value);
    const notAllowedKeys = ['KeyE'];

    // In case currentValue is 0
    if (currentValue !== null && currentValue !== undefined) {
      if (props.max && currentValue === props.max) {
        notAllowedKeys.push('ArrowUp');
      }
      // Incase min is 0
      if (props.min !== null && props.min !== undefined && currentValue === props.min) {
        notAllowedKeys.push('ArrowDown');
      }
    }

    if (notAllowedKeys.includes(e.code)) {
      e.preventDefault();
    }
    return;
  }

  if (props.preventInput && props.maxLength) {
    const isControlKey =
      NAVIGATION_KEYS.includes(e.code) ||
      ((e.ctrlKey || e.metaKey) && CONTROL_KEYS.includes(e.code));
    const target = e.target as HTMLInputElement;
    if (target.value.length >= parseInt(props.maxLength, 10) && !isControlKey) {
      e.preventDefault();
    }
  }
};

const onPaste = (event: ClipboardEvent) => {
  const pasteText = event.clipboardData?.getData('text').replace(/[\n\r]/g, '') || '';
  const currentText = value.value ?? '';
  const inputElement = event.target as HTMLInputElement;
  const selectionStart = inputElement.selectionStart || 0;
  const selectionEnd = inputElement.selectionEnd || 0;

  const beforeText = currentText.slice(0, selectionStart);
  const afterText = currentText.slice(selectionEnd, currentText.length);
  const newText = beforeText + pasteText + afterText;

  if (newText.length > parseInt(props.maxLength as string, 10) && !props.allowInputMaxLength) {
    event.preventDefault();
    return;
  }

  if (props.pattern) {
    if (props.pattern.test(pasteText)) {
      if (formatNumberText(newText)) {
        if ((value.value?.length ?? 0) > Number(props.maxLength)) {
          value.value = '';
          handleModelUpdate('');
        }
        return;
      }
      value.value = newText;
      handleModelUpdate(newText);
      return;
    }
    value.value = currentText;
    handleModelUpdate(currentText);
    return;
  }

  if (props.type === InputTextTypes.Number) {
    if (/[+\-,.]/.test(newText)) {
      updateValidationValueForInputNumber('');
      return;
    }
    updateValidationValueForInputNumber(newText);
    return;
  }

  // isFormatNumber can't go with type Number
  if (props.isFormatNumber) {
    const numberValue = newText?.replace(/,/g, '');
    if (!isNaN(Number(numberValue))) {
      const formattedNumber = formatNumberMultipleWithCommas(Number(numberValue));
      value.value = formattedNumber;
      handleModelUpdate(formattedNumber);
      return;
    }
  }

  value.value = newText;
  handleModelUpdate(newText);
};

watch(
  () => value.value,
  (newValue?: string) => {
    emit('change', newValue);
  },
  { once: true }
);
const focus = () => {
  inputTextRef.value?.focus();
};

defineExpose({ focus });
</script>

<style lang="scss" scoped>
.stds-input-clear-button {
  cursor: pointer;
}

.stds-input-container.none-style {
  border: none;
}
input:focus {
  outline: none;
}
// remove input background color for Chrome autocomplete
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s;
}
</style>
