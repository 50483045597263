export const CONTROL_KEYS = ['KeyA', 'KeyC', 'KeyX', 'KeyV', 'KeyZ', 'KeyY'];

export const NAVIGATION_KEYS = [
  'Backspace',
  'Delete',
  'ArrowLeft',
  'ArrowRight',
  'ArrowUp',
  'ArrowDown',
  'Home',
  'End'
];
